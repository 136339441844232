import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter  } from 'react-router-dom'
import { ThemeSwitcherProvider } from 'react-css-theme-switcher';
import store from './store';
import history from './history'
import Layouts from './layouts'
import { THEME_CONFIG } from './configs/AppConfig';
import './lang'
import mockServer from './mock'
import { AuthProvider } from 'contexts/AuthContext';
import { NotificationHandlerProvider } from 'contexts/Resource/NotificationHandlerContext';
import { ConfigProvider } from 'antd';

const themes = {
  dark: `${process.env.PUBLIC_URL}/css/dark-theme.css`,
  light: `${process.env.PUBLIC_URL}/css/light-theme.css`,
};

const environment = process.env.NODE_ENV

if (environment !== 'production') {
	mockServer({ environment })
}

document.title = process.env.REACT_APP_TITLE;

function App() {
  return (
    <div className="App">
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: '#486a7e',
          },
          components: {
            Button: {
              colorPrimary: '#486a7e',
              colorPrimaryBgHover: '#38596c',
              colorPrimaryHover: '#38596c',
              colorBorder: '#6b9cb8',
            },
            Input: {
              colorPrimary: '#486a7e',
              colorPrimaryBgHover: '#38596c',
              colorBorder: '#6b9cb8',
              colorTextPlaceholder: '#c4c4c4',
            },
            Select: {
              colorPrimary: '#486a7e',
              colorPrimaryBgHover: '#38596c',
              colorBorder: '#6b9cb8',
              colorTextPlaceholder: '#c4c4c4',
            },
            InputNumber: {
              colorPrimary: '#486a7e',
              colorPrimaryBgHover: '#38596c',
              colorBorder: '#6b9cb8',
              colorTextPlaceholder: '#c4c4c4',
            },
            DatePicker: {
              colorPrimary: '#486a7e',
              colorPrimaryBgHover: '#38596c',
              colorBorder: '#6b9cb8',
              colorTextPlaceholder: '#c4c4c4',
            },
            Radio: {
              colorPrimary: '#486a7e',
              colorPrimaryBgHover: '#38596c',
              colorBorder: '#6b9cb8',
              colorTextPlaceholder: '#c4c4c4',
            },
            Table: {
              paddingContentHorizontal: "3px",
              paddingContentVertical: "3px",
            },
            Card: {
              padding: "4px"
            },
          },
        }}
      >
        <NotificationHandlerProvider>
          <AuthProvider>
            <Provider store={store}>
              <BrowserRouter history={history}>
                <ThemeSwitcherProvider 
                  themeMap={themes} 
                  defaultTheme={THEME_CONFIG.currentTheme} 
                  insertionPoint="styles-insertion-point"
                >
                  <Layouts />
                </ThemeSwitcherProvider>
              </BrowserRouter>  
            </Provider>
          </AuthProvider>
        </NotificationHandlerProvider>
      </ConfigProvider>
    </div>
  );
}

export default App;
